import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Gallery8 from '../components/gallery8'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container10">
      <Helmet>
        <title>Mayyil</title>
        <meta property="og:title" content="Mayyil" />
      </Helmet>
      <header data-thq="thq-navbar" className="home-navbar-interactive">
        <img src="/logonew-200h.png" className="home-image10" />
        <div className="home-container11">
          <div data-thq="thq-navbar-nav" className="home-desktop-menu">
            <nav className="home-links1">
              <Link to="/" className="home-navlink1">
                Home
              </Link>
              <Link to="/menu" className="home-navlink2">
                Menu
              </Link>
              <Link to="/events" className="home-navlink3">
                Events
              </Link>
              <Link to="/contact" className="home-navlink4">
                Contacts
              </Link>
            </nav>
            <div className="home-buttons1">
              <button className="home-login1 button">
                <svg viewBox="0 0 1024 1024" className="home-icon10">
                  <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
                </svg>
              </button>
              <svg viewBox="0 0 1024 1024" className="home-icon12">
                <path d="M960 0l-448 128-448-128c0 0-4.5 51.698 0 128l448 140.090 448-140.090c4.498-76.302 0-128 0-128zM72.19 195.106c23.986 250.696 113.49 672.234 439.81 828.894 326.32-156.66 415.824-578.198 439.81-828.894l-439.81 165.358-439.81-165.358z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="home-burger-menu">
          <svg viewBox="0 0 1024 1024" className="home-icon14">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="home-mobile-menu">
          <div className="home-nav">
            <div className="home-top">
             
              <div data-thq="thq-close-menu" className="home-close-menu">
                <svg viewBox="0 0 1024 1024" className="home-icon16">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="home-links2">
            <Link to="/" className="home-navlink1">
                Home
              </Link>
              <Link to="/menu" className="home-navlink1">
                Menu
              </Link>
              <Link to="/events" className="home-navlink1">
                Events
              </Link>
              <Link to="/contact" className="home-navlink1">
                Contacts
              </Link>
            </nav>
            
          </div>
          <div>
        
          </div>
        </div>
      </header>
      <Gallery8
        image1Alt="gallery8-mayyil is a lebanese restaurant located in the heart of london, known for its vibrant atmosphere and delicious lebanese street food. our menu is carefully crafted to offer a taste of authentic lebanese cuisine with a modern twist. at mayyil, we pride ourselves on providing quick and efficient service without compromising on quality. our success in london has led to plans for expansion both locally and internationally. the name 'mayyil' symbolizes the convenience and speed of our wholesome eats, making it the perfect spot for a quick and satisfying meal."
        rootClassName="gallery8root-class-name"
      ></Gallery8>
      <div className="home-gallery-card1">
        <div className="home-container12">
          <animate-on-reveal
            animation="flipInX"
            duration="500ms"
            delay="0s"
            direction="normal"
            easing="ease"
            iteration="1"
          >
            <img
              alt="image"
              src="/sticker%202-600h.png"
              data-thq-animate-on-reveal="true"
              className="home-image11"
            />
          </animate-on-reveal>
          <animate-on-reveal
            animation="slideInDown"
            duration="700ms"
            delay="0s"
            direction="normal"
            easing="ease"
            iteration="1"
          >
            <h1 data-thq-animate-on-reveal="true" className="home-aboutus">
              About Us
            </h1>
          </animate-on-reveal>
        </div>
        <h1 className="home-text15">
          London’s most inspired-Lebanese Restaurant
        </h1>
        <span className="home-hhhh1">
          Established in 2022, Mayyil is a quick service restaurant that offers
          an authentic Lebanese street food experience. The company started its
          first branch in London in September 2022, which saw overwhelming
          demand and appreciation for its food. Encouraged by this success,
          Mayyil decided to expand and opened a second location in Notting Hill
          Gate, London in January 2024.
        </span>
        <div className="home-separator"></div>
        <span className="home-hhhh2">
          Blending tradition with innovation across its two London branches,
          Mayyil aims to share the best of Lebanese cuisine and hospitality in a
          fast-paced world. The name &quot;Mayyil&quot;, which translates to
          &quot;Pass by&quot;, reflects the convenience of Mayyil&apos;s quick,
          wholesome eats, catering to customers seeking a flavorful dining
          option on the go. Looking ahead, the company plans to continue its
          growth in the UK and expand internationally, with the first Emirati
          branches set to open by the end of 2024.
        </span>
        <animate-on-reveal
          animation="fadeInBottomLeft"
          duration="500ms"
          delay="0s"
          direction="normal"
          easing="ease"
          iteration="1"
        >
          <img
            alt="image"
            src="/external/sticker%201-200h.png"
            data-thq-animate-on-reveal="true"
            className="home-image12"
          />
        </animate-on-reveal>
        <svg viewBox="0 0 808.5942857142857 1024" className="home-icon24">
          <path d="M790.857 529.714l-758.857 421.714c-17.714 9.714-32 1.143-32-18.857v-841.143c0-20 14.286-28.571 32-18.857l758.857 421.714c17.714 9.714 17.714 25.714 0 35.429z"></path>
        </svg>
        <img
          alt="image"
          src="/external/sticker%203-1400h.png"
          className="home-image13"
        />
      </div>
      <div className="home-gallery-card2">
        <img
          alt="image"
          src="https://images.unsplash.com/photo-1523755231516-e43fd2e8dca5?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fG1pbmltYWxpc20lMjBjb3VjaHxlbnwwfHx8fDE2MjY0NDg1NTk&amp;ixlib=rb-1.2.1&amp;h=1000"
          className="home-image14"
        />
      </div>
      <div className="home-container13">
        <animate-on-reveal
          animation="fadeInDown"
          duration="500ms"
          delay="0s"
          direction="normal"
          easing="ease"
          iteration="1"
        >
          <img
            alt="image"
            src="/external/phone-700h.png"
            data-thq-animate-on-reveal="true"
            className="home-image15"
          />
        </animate-on-reveal>
        <div className="home-container14">
          <div className="home-container15">
            <h1 className="home-text16">Our Mobile App</h1>
            <span className="home-text17">
              Download Mayyil mobile app for ordering and loyalty rewards.
            </span>
            <div className="home-container16">
              <button type="button" className="home-button1 button">
                <svg
                  viewBox="0 0 796.0137142857142 1024"
                  className="home-icon26"
                >
                  <path d="M796 694.286c-14.286 45.143-37.143 93.143-70.286 142.857-49.143 74.857-98.286 112-146.857 112-19.429 0-45.714-6.286-80-18.286-33.714-12.571-62.857-18.286-86.286-18.286-22.857 0-50.286 6.286-81.143 18.857-31.429 13.143-56.571 19.429-75.429 19.429-58.857 0-115.429-49.714-172-148-55.429-98.286-84-193.714-84-287.429 0-87.429 21.714-158.286 64.571-213.714 42.857-54.857 96.571-82.286 162.286-82.286 28 0 61.143 5.714 101.143 17.143 39.429 11.429 65.714 17.143 78.857 17.143 16.571 0 44-6.286 81.714-19.429 37.714-12.571 70.857-19.429 98.857-19.429 45.714 0 86.286 12.571 121.714 37.143 20 13.714 40 33.143 59.429 57.143-29.714 25.143-51.429 47.429-65.143 67.429-24.571 35.429-37.143 74.857-37.143 118.286 0 46.857 13.143 89.714 39.429 127.429s56.571 61.714 90.286 72zM581.143 24c0 23.429-5.714 49.714-16.571 77.714-11.429 28.571-29.143 54.857-53.143 78.857-20.571 20.571-41.143 34.286-61.714 41.143-13.143 4-32.571 7.429-59.429 9.714 1.143-56.571 16-105.714 44.571-146.857s76.571-69.143 142.857-84.571c1.143 5.143 2.286 9.143 2.857 12.571 0 4 0.571 7.429 0.571 11.429z"></path>
                </svg>
                <span className="home-text18">
                  <span className="home-text19">Download on the </span>
                  <br></br>
                  <span className="home-text21">App Store</span>
                </span>
              </button>
              <button type="button" className="home-button2 button">
                <svg
                  viewBox="0 0 808.5942857142857 1024"
                  className="home-icon28"
                >
                  <path d="M790.857 529.714l-758.857 421.714c-17.714 9.714-32 1.143-32-18.857v-841.143c0-20 14.286-28.571 32-18.857l758.857 421.714c17.714 9.714 17.714 25.714 0 35.429z"></path>
                </svg>
                <span className="home-text22">
                  <span className="home-text23">Download on the</span>
                  <br className="home-text24"></br>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="home-text26">Play Store</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="home-container17">
        <img
          alt="image"
          src="/external/mayyil%20website%20home-02%202-1700h.png"
          className="home-image16"
        />
        <animate-on-reveal
          animation="slideInLeft"
          duration="700ms"
          delay="0s"
          direction="normal"
          easing="ease"
          iteration="1"
        >
          <h1
            data-thq-animate-on-reveal="true"
            className="home-subscribe-to-our-blog"
          >
            <span>Subscribe to our blogs</span>
            <br></br>
            <br></br>
          </h1>
        </animate-on-reveal>
        <span className="home-text30">
          Join our 10,256+ subscribers list today!
        </span>
        <input
          type="text"
          placeholder="Email Address"
          className="home-textinput1 input"
        />
        <animate-on-reveal
          animation="fadeInLeft"
          duration="300ms"
          delay="0s"
          direction="normal"
          easing="ease"
          iteration="1"
        >
          <button
            type="button"
            data-thq-animate-on-reveal="true"
            className="home-button3 button"
          >
            <span className="home-text31">
              <span>Subscribe Now</span>
              <br></br>
            </span>
          </button>
        </animate-on-reveal>
        <div className="home-container18">
          <span className="home-text34">How’s Your Experience so far?</span>
          <div className="home-stats">
            <div className="home-stat1">
              <svg
                height="24"
                width="24"
                viewBox="0 0 24 24"
                className="home-icon30"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                >
                  <path
                    fill="currentColor"
                    d="M8.5 9a.5.5 0 1 1 0-1a.5.5 0 0 1 0 1m7 0a.5.5 0 1 1 0-1a.5.5 0 0 1 0 1"
                  ></path>
                  <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10"></path>
                  <path d="M7.5 15.5s1.5-2 4.5-2s4.5 2 4.5 2"></path>
                </g>
              </svg>
            </div>
            <div className="home-stat2">
              <svg
                height="24"
                width="24"
                viewBox="0 0 48 48"
                className="home-icon35"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="4"
                >
                  <path d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z"></path>
                  <path
                    stroke-linecap="round"
                    d="M31 35s-2-4-7-4s-7 4-7 4m16-16l-4 2m-14-2l4 2"
                  ></path>
                </g>
              </svg>
            </div>
            <div className="home-stat3">
              <svg
                height="24"
                width="24"
                viewBox="0 0 48 48"
                className="home-icon39"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="4"
                >
                  <path d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z"></path>
                  <path
                    stroke-linecap="round"
                    d="M31 31s-2 4-7 4s-7-4-7-4m14-13v4m-14-4v4"
                  ></path>
                </g>
              </svg>
            </div>
            <div className="home-stat4">
              <path d="M512 0c-282.77 0-512 229.23-512 512s229.226 512 512 512c282.77 0 512-229.23 512-512s-229.23-512-512-512zM704 236c63.962 0 116 52.038 116 116 0 6.378-0.546 12.876-1.628 19.312-1.618 9.632-9.958 16.688-19.724 16.688s-18.108-7.056-19.722-16.69c-6.16-36.684-37.67-53.31-74.926-53.31s-68.766 16.626-74.924 53.31c-1.616 9.632-9.956 16.69-19.722 16.69-0.002 0 0 0-0.002 0-9.766 0-18.106-7.056-19.722-16.688-1.084-6.436-1.63-12.934-1.63-19.312 0-63.962 52.038-116 116-116zM320 236c63.962 0 116 52.038 116 116 0 6.378-0.548 12.876-1.628 19.312-1.618 9.632-9.956 16.688-19.724 16.688s-18.106-7.056-19.722-16.69c-6.16-36.684-37.67-53.31-74.926-53.31s-68.766 16.626-74.924 53.31c-1.616 9.632-9.956 16.69-19.722 16.69 0 0 0 0 0 0-9.766 0-18.106-7.056-19.724-16.688-1.082-6.436-1.63-12.934-1.63-19.312 0-63.962 52.038-116 116-116zM192 576h192v247.846c-110.094-28.606-192-129.124-192-247.846zM448 832v-256h128v256h-128zM640 823.846v-247.846h192c0 118.722-81.904 219.24-192 247.846z"></path>
              <path d="M512 1024c282.77 0 512-229.23 512-512s-229.23-512-512-512-512 229.23-512 512 229.23 512 512 512zM512 96c229.75 0 416 186.25 416 416s-186.25 416-416 416-416-186.25-416-416 186.25-416 416-416zM512 598.76c115.95 0 226.23-30.806 320-84.92-14.574 178.438-153.128 318.16-320 318.16-166.868 0-305.422-139.872-320-318.304 93.77 54.112 204.050 85.064 320 85.064zM256 352c0-53.019 28.654-96 64-96s64 42.981 64 96c0 53.019-28.654 96-64 96s-64-42.981-64-96zM640 352c0-53.019 28.654-96 64-96s64 42.981 64 96c0 53.019-28.654 96-64 96s-64-42.981-64-96z"></path>
              <path d="M512 1024c282.77 0 512-229.23 512-512s-229.23-512-512-512-512 229.23-512 512 229.23 512 512 512zM512 96c229.75 0 416 186.25 416 416s-186.25 416-416 416-416-186.25-416-416 186.25-416 416-416zM512 598.76c115.95 0 226.23-30.806 320-84.92-14.574 178.438-153.128 318.16-320 318.16-166.868 0-305.422-139.872-320-318.304 93.77 54.112 204.050 85.064 320 85.064zM256 352c0-53.019 28.654-96 64-96s64 42.981 64 96c0 53.019-28.654 96-64 96s-64-42.981-64-96zM640 352c0-53.019 28.654-96 64-96s64 42.981 64 96c0 53.019-28.654 96-64 96s-64-42.981-64-96z"></path>
              <svg
                height="24"
                width="24"
                viewBox="0 0 24 24"
                className="home-icon46"
              >
                <mask id="lineMdEmojiGrin0">
                  <path
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-width="2"
                    d="M8 14C8.5 15.5 9.79086 17 12 17C14.2091 17 15.5 15.5 16 14M8 14C9 14.5 10 15 12 15C14 15 15 14.5 16 14"
                  ></path>
                </mask>
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-dasharray="60"
                  stroke-dashoffset="60"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3Z"
                >
                  <animate
                    fill="freeze"
                    attributeName="stroke-dashoffset"
                    dur="0.5s"
                    values="60;0"
                  ></animate>
                </path>
                <g fill="currentColor">
                  <rect
                    width="0"
                    height="7"
                    x="6"
                    y="12"
                    mask="url(#lineMdEmojiGrin0)"
                  >
                    <animate
                      fill="freeze"
                      attributeName="width"
                      begin="1s"
                      dur="0.2s"
                      values="0;12"
                    ></animate>
                  </rect>
                  <ellipse cx="9" cy="9.5" fill-opacity="0" rx="1" ry="1.5">
                    <animate
                      fill="freeze"
                      attributeName="fill-opacity"
                      begin="0.6s"
                      dur="0.2s"
                      values="0;1"
                    ></animate>
                  </ellipse>
                  <ellipse cx="15" cy="9.5" fill-opacity="0" rx="1" ry="1.5">
                    <animate
                      fill="freeze"
                      attributeName="fill-opacity"
                      begin="0.8s"
                      dur="0.2s"
                      values="0;1"
                    ></animate>
                  </ellipse>
                </g>
              </svg>
              <path d="M512 1024c282.77 0 512-229.23 512-512s-229.23-512-512-512-512 229.23-512 512 229.23 512 512 512zM512 96c229.75 0 416 186.25 416 416s-186.25 416-416 416-416-186.25-416-416 186.25-416 416-416zM512 598.76c115.95 0 226.23-30.806 320-84.92-14.574 178.438-153.128 318.16-320 318.16-166.868 0-305.422-139.872-320-318.304 93.77 54.112 204.050 85.064 320 85.064zM256 352c0-53.019 28.654-96 64-96s64 42.981 64 96c0 53.019-28.654 96-64 96s-64-42.981-64-96zM640 352c0-53.019 28.654-96 64-96s64 42.981 64 96c0 53.019-28.654 96-64 96s-64-42.981-64-96z"></path>
              <path d="M512 1024c282.77 0 512-229.23 512-512s-229.23-512-512-512-512 229.23-512 512 229.23 512 512 512zM512 96c229.75 0 416 186.25 416 416s-186.25 416-416 416-416-186.25-416-416 186.25-416 416-416zM512 598.76c115.95 0 226.23-30.806 320-84.92-14.574 178.438-153.128 318.16-320 318.16-166.868 0-305.422-139.872-320-318.304 93.77 54.112 204.050 85.064 320 85.064zM256 352c0-53.019 28.654-96 64-96s64 42.981 64 96c0 53.019-28.654 96-64 96s-64-42.981-64-96zM640 352c0-53.019 28.654-96 64-96s64 42.981 64 96c0 53.019-28.654 96-64 96s-64-42.981-64-96z"></path>
              <path d="M512 1024c282.77 0 512-229.23 512-512s-229.23-512-512-512-512 229.23-512 512 229.23 512 512 512zM512 96c229.75 0 416 186.25 416 416s-186.25 416-416 416-416-186.25-416-416 186.25-416 416-416zM512 598.76c115.95 0 226.23-30.806 320-84.92-14.574 178.438-153.128 318.16-320 318.16-166.868 0-305.422-139.872-320-318.304 93.77 54.112 204.050 85.064 320 85.064zM256 352c0-53.019 28.654-96 64-96s64 42.981 64 96c0 53.019-28.654 96-64 96s-64-42.981-64-96zM640 352c0-53.019 28.654-96 64-96s64 42.981 64 96c0 53.019-28.654 96-64 96s-64-42.981-64-96z"></path>
              <path d="M512 1024c282.77 0 512-229.23 512-512s-229.23-512-512-512-512 229.23-512 512 229.23 512 512 512zM512 96c229.75 0 416 186.25 416 416s-186.25 416-416 416-416-186.25-416-416 186.25-416 416-416zM512 598.76c115.95 0 226.23-30.806 320-84.92-14.574 178.438-153.128 318.16-320 318.16-166.868 0-305.422-139.872-320-318.304 93.77 54.112 204.050 85.064 320 85.064zM256 352c0-53.019 28.654-96 64-96s64 42.981 64 96c0 53.019-28.654 96-64 96s-64-42.981-64-96zM640 352c0-53.019 28.654-96 64-96s64 42.981 64 96c0 53.019-28.654 96-64 96s-64-42.981-64-96z"></path>
            </div>
            <div className="home-stat5">
              <svg
                height="24"
                width="24"
                viewBox="0 0 24 24"
                className="home-icon62"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  color="currentColor"
                >
                  <path d="M12.5 2.012A10 10 0 0 0 12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10c0-.863-.11-1.701-.315-2.5"></path>
                  <path d="M8 15a5 5 0 0 0 4 2a5 5 0 0 0 4-2m-6-5.5H8.707c-.453 0-.887.18-1.207.5m6.5-.5h1.293c.453 0 .887.18 1.207.5m-.612-7.669c.939-.548 1.758-.327 2.25.025c.202.144.303.216.362.216c.06 0 .16-.072.362-.216c.492-.352 1.311-.573 2.25-.025c1.232.72 1.51 3.094-1.33 5.097c-.542.381-.813.572-1.282.572s-.74-.19-1.281-.572c-2.841-2.003-2.563-4.377-1.33-5.097"></path>
                </g>
              </svg>
            </div>
          </div>
          <button type="button" className="home-button4 button">
            Send Feedback
          </button>
        </div>
      </div>
      <footer className="home-footer1">
        <div className="home-container19">
          <div className="home-links-container">
            <div className="home-container20">
              <div className="home-product-container1">
                <span className="home-text35">
                  <span>Knightsbridge London branch address:</span>
                  <br></br>
                  <span>17 Knightsbridge Green, London, SW1X 7QL</span>
                  <br></br>
                  <br></br>
                  <span>Phone:</span>
                  <br></br>
                  <span>02076220022</span>
                </span>
              </div>
              <div className="home-product-container2">
                <span className="home-text44">
                  <span className="home-text45">
                    Notting Hill branch address:
                  </span>
                  <br className="home-text46"></br>
                  <span className="home-text47">
                    14 Pembridge Road, London, W11 3HL
                  </span>
                  <br className="home-text48"></br>
                  <br className="home-text49"></br>
                  <span className="home-text50">Phone:</span>
                  <br className="home-text51"></br>
                  <span className="home-text52">02038279000</span>
                  <br></br>
                </span>
                <div className="home-company-container">
                  <span className="home-text54">
                    <span>Reserve a table</span>
                    <br></br>
                    <br></br>
                    <span>
                      If you want to reserve a table for your company, call us.
                    </span>
                    <br></br>
                    <br></br>
                    <span>+442089611166</span>
                    <br></br>
                  </span>
                  <button type="button" className="home-button5 button">
                    Reserve a Table
                  </button>
                </div>
              </div>
              <div className="home-container21">
                <div className="home-contact"></div>
                <div className="home-socials">
                  <span className="home-text63">Follow Us</span>
                  <div className="home-icon-group2">
                    <svg
                      viewBox="0 0 950.8571428571428 1024"
                      className="home-icon66"
                    >
                      <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="home-icon68"
                    >
                      <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 602.2582857142856 1024"
                      className="home-icon70"
                    >
                      <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="home-container22">
        <h1 className="home-text64">Get The latest</h1>
        <div className="home-container23">
          <input
            type="text"
            placeholder="Email Address"
            className="home-textinput2 input"
          />
          <input
            type="text"
            placeholder="Full Name"
            className="home-textinput3 input"
          />
          <button type="button" className="home-button6 button">
            Subscribe
          </button>
        </div>
        <div className="home-container24">
          <animate-on-reveal
            animation="zoomInDown"
            duration="700ms"
            delay="0s"
            direction="normal"
            easing="ease"
            iteration="1"
          >
            <img
              alt="image"
              src="/external/facebook%20icon-200h.png"
              data-thq-animate-on-reveal="true"
              className="home-image17"
            />
          </animate-on-reveal>
          <animate-on-reveal
            animation="zoomInDown"
            duration="700ms"
            delay="0s"
            direction="normal"
            easing="ease"
            iteration="1"
          >
            <img
              alt="image"
              src="/external/insta%20icon-200h.png"
              data-thq-animate-on-reveal="true"
              className="home-image18"
            />
          </animate-on-reveal>
          <animate-on-reveal
            animation="zoomInDown"
            duration="700ms"
            delay="0s"
            direction="normal"
            easing="ease"
            iteration="1"
          >
            <img
              alt="image"
              src="/external/tiktok%20icon-200h.png"
              data-thq-animate-on-reveal="true"
              className="home-image19"
            />
          </animate-on-reveal>
        </div>
      </div>
      <footer className="home-footer2">
        <span className="home-text65">2024, All rights reserved</span>
      </footer>
    </div>
  )
}

export default Home
