import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './events.css'

const Events = (props) => {
  return (
    <div className="events-container10">
      <Helmet>
        <title>Events - Mayyil</title>
        <meta
          property="og:title"
          content="Events - Mayyil"
        />
      </Helmet>
      <header data-thq="thq-navbar" className="events-navbar-interactive">
        <img src="/logonew-600h.png" className="events-image1" />
        <div className="events-container11">
          <div data-thq="thq-navbar-nav" className="events-desktop-menu">
            <nav className="events-links1">
              <Link to="/" className="events-navlink1">
                Home
              </Link>
              <Link to="/menu" className="events-navlink2">
                Menu
              </Link>
              <Link to="/events" className="events-navlink3">
                Events
              </Link>
              <Link to="/contact" className="events-navlink4">
                Contacts
              </Link>
            </nav>
            <div className="events-buttons1">
              <button className="events-login1 button">
                <svg viewBox="0 0 1024 1024" className="events-icon10">
                  <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
                </svg>
              </button>
              <svg viewBox="0 0 1024 1024" className="events-icon12">
                <path d="M960 0l-448 128-448-128c0 0-4.5 51.698 0 128l448 140.090 448-140.090c4.498-76.302 0-128 0-128zM72.19 195.106c23.986 250.696 113.49 672.234 439.81 828.894 326.32-156.66 415.824-578.198 439.81-828.894l-439.81 165.358-439.81-165.358z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="events-burger-menu">
          <svg viewBox="0 0 1024 1024" className="events-icon14">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="events-mobile-menu">
          <div className="events-nav">
            <div className="events-top">
              
              <div data-thq="thq-close-menu" className="events-close-menu">
                <svg viewBox="0 0 1024 1024" className="events-icon16">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="events-links2">
            <Link to="/" className="home-navlink1">
                Home
              </Link>
              <Link to="/menu" className="home-navlink1">
                Menu
              </Link>
              <Link to="/events" className="home-navlink1">
                Events
              </Link>
              <Link to="/contact" className="home-navlink1">
                Contacts
              </Link>
            </nav>
         
          </div>
        
        </div>
      </header>
      <div className="events-container12">
        <svg viewBox="0 0 1024 1024" className="events-icon24">
          <path d="M728.992 512c137.754-87.334 231.008-255.208 231.008-448 0-21.676-1.192-43.034-3.478-64h-889.042c-2.29 20.968-3.48 42.326-3.48 64 0 192.792 93.254 360.666 231.006 448-137.752 87.334-231.006 255.208-231.006 448 0 21.676 1.19 43.034 3.478 64h889.042c2.288-20.966 3.478-42.324 3.478-64 0.002-192.792-93.252-360.666-231.006-448zM160 960c0-186.912 80.162-345.414 224-397.708v-100.586c-143.838-52.29-224-210.792-224-397.706v0h704c0 186.914-80.162 345.416-224 397.706v100.586c143.838 52.294 224 210.796 224 397.708h-704zM619.626 669.594c-71.654-40.644-75.608-93.368-75.626-125.366v-64.228c0-31.994 3.804-84.914 75.744-125.664 38.504-22.364 71.808-56.348 97.048-98.336h-409.582c25.266 42.032 58.612 76.042 97.166 98.406 71.654 40.644 75.606 93.366 75.626 125.366v64.228c0 31.992-3.804 84.914-75.744 125.664-72.622 42.18-126.738 125.684-143.090 226.336h501.67c-16.364-100.708-70.53-184.248-143.212-226.406z"></path>
        </svg>
        <h1 className="events-text15">Coming Soon</h1>
      </div>
      <footer className="home-footer1">
        <div className="home-container19">
          <div className="home-links-container">
            <div className="home-container20">
              <div className="home-product-container1">
                <span className="home-text35">
                  <span>Knightsbridge London branch address:</span>
                  <br></br>
                  <span>17 Knightsbridge Green, London, SW1X 7QL</span>
                  <br></br>
                  <br></br>
                  <span>Phone:</span>
                  <br></br>
                  <span>02076220022</span>
                </span>
              </div>
              <div className="home-product-container2">
                <span className="home-text44">
                  <span className="home-text45">
                    Notting Hill branch address:
                  </span>
                  <br className="home-text46"></br>
                  <span className="home-text47">
                    14 Pembridge Road, London, W11 3HL
                  </span>
                  <br className="home-text48"></br>
                  <br className="home-text49"></br>
                  <span className="home-text50">Phone:</span>
                  <br className="home-text51"></br>
                  <span className="home-text52">02038279000</span>
                  <br></br>
                </span>
                <div className="home-company-container">
                  <span className="home-text54">
                    <span>Reserve a table</span>
                    <br></br>
                    <br></br>
                    <span>
                      If you want to reserve a table for your company, call us.
                    </span>
                    <br></br>
                    <br></br>
                    <span>+442089611166</span>
                    <br></br>
                  </span>
                  <button type="button" className="home-button5 button">
                    Reserve a Table
                  </button>
                </div>
              </div>
              <div className="home-container21">
                <div className="home-contact"></div>
                <div className="home-socials">
                  <span className="home-text63">Follow Us</span>
                  <div className="home-icon-group2">
                    <svg
                      viewBox="0 0 950.8571428571428 1024"
                      className="home-icon66"
                    >
                      <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="home-icon68"
                    >
                      <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 602.2582857142856 1024"
                      className="home-icon70"
                    >
                      <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="home-container22">
        <h1 className="home-text64">Get The latest</h1>
        <div className="home-container23">
          <input
            type="text"
            placeholder="Email Address"
            className="home-textinput2 input"
          />
          <input
            type="text"
            placeholder="Full Name"
            className="home-textinput3 input"
          />
          <button type="button" className="home-button6 button">
            Subscribe
          </button>
        </div>
        <div className="home-container24">
          <animate-on-reveal
            animation="zoomInDown"
            duration="700ms"
            delay="0s"
            direction="normal"
            easing="ease"
            iteration="1"
          >
            <img
              alt="image"
              src="/external/facebook%20icon-200h.png"
              data-thq-animate-on-reveal="true"
              className="home-image17"
            />
          </animate-on-reveal>
          <animate-on-reveal
            animation="zoomInDown"
            duration="700ms"
            delay="0s"
            direction="normal"
            easing="ease"
            iteration="1"
          >
            <img
              alt="image"
              src="/external/insta%20icon-200h.png"
              data-thq-animate-on-reveal="true"
              className="home-image18"
            />
          </animate-on-reveal>
          <animate-on-reveal
            animation="zoomInDown"
            duration="700ms"
            delay="0s"
            direction="normal"
            easing="ease"
            iteration="1"
          >
            <img
              alt="image"
              src="/external/tiktok%20icon-200h.png"
              data-thq-animate-on-reveal="true"
              className="home-image19"
            />
          </animate-on-reveal>
        </div>
      </div>
      <footer className="home-footer2">
        <span className="home-text65">2024, All rights reserved</span>
      </footer>
    </div>
  )
}

export default Events
