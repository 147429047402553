import React from 'react'

import PropTypes from 'prop-types'

import './gallery8.css'

const Gallery8 = (props) => {
  return (
    <div
      className={`gallery8-gallery3 thq-section-padding ${props.rootClassName} `}
    >
      <div className="gallery8-max-width thq-section-max-width">
        <div className="gallery8-container">
          <div className="gallery8-content">
            <div
              data-thq="slider"
              data-autoplay="true"
              data-navigation="true"
              data-pagination="true"
              data-pause-autoplay-on-mouse-enter="true"
              data-disable-autoplay-on-interaction="false"
              className="gallery8-slider swiper"
            >
              <div data-thq="slider-wrapper" className="swiper-wrapper">
                <div
                  data-thq="slider-slide"
                  className="gallery8-slider-slide1 swiper-slide"
                >
                  <img
                    alt={props.image1Alt}
                    src={props.image1Src}
                    className="gallery8-image1 thq-img-ratio-4-3"
                  />
                </div>
                <div
                  data-thq="slider-slide"
                  className="gallery8-slider-slide2 swiper-slide"
                >
                  <img
                    alt={props.image2Alt}
                    src={props.image2Src}
                    className="gallery8-image2 thq-img-ratio-4-3"
                  />
                </div>
              </div>
              <div
                data-thq="slider-button-prev"
                className="gallery8-slider-button-prev swiper-button-prev"
              ></div>
              <div
                data-thq="slider-button-next"
                className="gallery8-slider-button-next swiper-button-next"
              ></div>
              <div
                data-thq="slider-pagination"
                className="gallery8-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
              >
                <div
                  data-thq="slider-pagination-bullet"
                  className="gallery8-slider-pagination-bullet1 swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="gallery8-slider-pagination-bullet2 swiper-pagination-bullet"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery8.defaultProps = {
  image1Alt: 'image',
  rootClassName: '',
  image2Alt: 'image',
  image2Src: '/photo-2024-06-04-19-32-07%202-1400w.jpg',
  image1Src: '/photo-2024-06-04-19-32-07%203-1400w.jpg',
}

Gallery8.propTypes = {
  image1Alt: PropTypes.string,
  rootClassName: PropTypes.string,
  image2Alt: PropTypes.string,
  image2Src: PropTypes.string,
  image1Src: PropTypes.string,
}

export default Gallery8
