import React from 'react'

import PropTypes from 'prop-types'

import './gallery14.css'

const Gallery14 = (props) => {
  return (
    <div
      className={`gallery14-gallery3 thq-section-padding ${props.rootClassName} `}
    >
      <div className="gallery14-max-width thq-section-max-width">
        <div className="gallery14-section-title"></div>
        <div className="gallery14-content">
          <div className="gallery14-container1">
            <img
              alt="PlaceholderImage1302"
              src="/sojok-400h.png"
              className="gallery14-image11 thq-img-ratio-4-3"
            />
            <span className="gallery14-text1 thq-body-small">
              {props.image1Title}
            </span>
            <span className="gallery14-text2 thq-body-small">
              {props.image1Description}
            </span>
            <span className="gallery14-text3">{props.text}</span>
          </div>
          <div className="gallery14-container2">
            <img
              alt="PlaceholderImage1302"
              src="/sojok-400h.png"
              className="gallery14-image12 thq-img-ratio-4-3"
            />
            <span className="gallery14-text4 thq-body-small">
              {props.image1Title}
            </span>
            <span className="gallery14-text5 thq-body-small">
              {props.image1Description}
            </span>
            <span className="gallery14-text6">{props.text}</span>
          </div>
          <div className="gallery14-container3">
            <img
              alt="PlaceholderImage1302"
              src="/sojok-400h.png"
              className="gallery14-image13 thq-img-ratio-4-3"
            />
            <span className="gallery14-text7 thq-body-small">
              {props.image1Title}
            </span>
            <span className="gallery14-text8 thq-body-small">
              {props.image1Description}
            </span>
            <span className="gallery14-text9">{props.text}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery14.defaultProps = {
  image1Description:
    'spicy Lebanese style sausage with garlic sauce,lettuce, tomato & pickles.',
  text: '£ 8.00',
  image1Title: 'Sojok',
  rootClassName: '',
}

Gallery14.propTypes = {
  image1Description: PropTypes.string,
  text: PropTypes.string,
  image1Title: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Gallery14
